export default () => {

  const cards = document.querySelectorAll('.routing-card');  
  Array.prototype.forEach.call(cards, card => {  
    let down, up, link = card.querySelector('.routing-card__title a');
    card.onmousedown = () => down = +new Date();
    card.onmouseup = () => {
      up = +new Date();
      if ((up - down) < 200) {
        link.click();
      }
    }
  });

}