export default () => {

  const cards = document.querySelectorAll('.logo-carousel__cards .logo-carousel__slide');  
  Array.prototype.forEach.call(cards, card => {  
    let down, up, link = card.querySelector('.slide__title a');
    card.onmousedown = () => down = +new Date();
    card.onmouseup = () => {
      up = +new Date();
      if ((up - down) < 200) {
        link.click();
      }
    }
  });

}