export default () => {
  const search_icon = $('.search__toggle');
  // const search_icon_mobile = $('.header__search--mobile');

  const input = $('.site-header-search-form').find('input');
  const sec_search_icon = $('.site-header-search-form').find('button');

  // Show search field
  search_icon.on('click', function (e) {
    const form = $(this).next().find('.site-header-search-form')

    $(this).attr('aria-expanded', function(index, attr) {
      return attr == 'false' ? 'true' : 'false';
    });

    if ($(this).attr('aria-expanded') == 'true') {
      setTimeout(function() { $('.header__utility-nav__search__field input').focus(); }, 100);
    }

    form.fadeToggle();
    e.stopPropagation();
  })

  // Hide search field on click anywhere outside element
  $(document).on('click', function () {
      $('.site-header-search-form:not(#search-landing-form)').fadeOut();
      $('.search-results__search-box__autocomplete-results').fadeOut();

      // Clear results from AJAX search on click outside element
      $('.header__utility-nav__search__field__autocomplete-results').empty();
      $('.header__search--mobile__field__autocomplete-results').empty();
      $('.search-results__search-box__autocomplete-results').empty();

      // Set Aria attribute of site header search icon buttons to false on click outside of element
      search_icon.attr('aria-expanded', 'false');
  });

  sec_search_icon.on('click', function (e) {
    e.stopPropagation();
  })

  input.on('click', function (e) {
    e.stopPropagation();
  })

  // Hide search field on ESC key click
  $(document).on('keydown', function(e) {
    if ( e.keyCode === 27 ) { // ESC
      if ($('.site-header-search-form:not(#search-landing-form)').is(':visible')) {
        $('.site-header-search-form:not(#search-landing-form)').fadeOut();
        $('.search__toggle').attr('aria-expanded','false');
      }
      if ($('#search-landing-form').is(':visible')) {
        $('#search-landing-form .search-results__search-box__autocomplete-results').fadeOut();
      }
    }
  });

  // Predictive Search
  input.on('keyup', debounce(function() {
    const ref = $('.site-header-search-form:visible').find('input');
    console.log(ref.val());
    if (ref.val().length > 3) {
      let submitData  = {
        'action'    : 'search_ahead',
        'nonce'     : ref.data('nonce'),
        's_term'    : ref.val(),
      }

      // Ajax function
      $.ajax({
        url : input.data('url'),
        data : submitData,
        type : 'POST',
        success : function( response ){  
          console.log('header search', response);  
          if (ref.is('#s')) {
            $('.header__utility-nav__search__field__autocomplete-results').html(response).show();
          } else if (ref.is('#s-search-landing')) {
            $('.search-results__search-box__autocomplete-results').html(response).css('display', 'flex');
          } else if (ref.is('#s2')) {
            $('.header__search--mobile__field__autocomplete-results').html(response).css('display', 'flex');
          }
        },
      });

      // DataLayer push
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'siteSearch',
        'eventCategory': 'site-search',
        'eventAction': 'search-success',
        'eventLabel': ref.val(),
      });

      // Show results when 3 characters have been entered
    } else {
      $('.header__utility-nav__search__field__autocomplete-results').hide();
      $('.header__search--mobile__field__autocomplete-results').hide();
      $('.search-results__search-box__autocomplete-results').hide();
    }
  }));


  // Trap focus inside search results predictive search modal
  const searchBox = document.getElementById('autocomplete-results-main');

  if (searchBox) {
    searchBox.addEventListener('keydown', function(event) {
      const searchItems = searchBox.querySelectorAll('a');
      const firstSearchItem = searchItems[0]; 
      const lastSearchItem = searchItems[searchItems.length - 1]; 

      if (event.key === 'Tab') {
        if (event.shiftKey && document.activeElement === firstSearchItem) {
          event.preventDefault();
          lastSearchItem.focus();
        } else if (!event.shiftKey && document.activeElement === lastSearchItem) {
          event.preventDefault();
          firstSearchItem.focus();
        }
      }
    });
  }

  // Make post count div on search results page un-tabbable (displaying 1 - x of y pages) if no results so SR doesn't announce div
  if ($('.search-results__inner__results__post-count').find('*').length < 1) {
    $('.search-results__inner__results__post-count').attr('tabindex', '-1');
  }

  // debounce fxn
  function debounce(func, timeout = 500){
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
  }
}