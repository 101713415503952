export default () => {
  $(window).on('custom-select-init',function(){
    // console.log('creating GForm custom selects...');
    // grab native selects
    const select = $('.ginput_container.ginput_container_select, .ginput_container_address').find('select');
    // create custom selects
    select.each(function () {
      // if there is already a custom select after the select, delete it
      if ($(this).next('.custom-select').length) {
        $(this).next().remove();
      }

      // make the native select un-focusable
      $(this).attr('tabindex','-1');

      // gather options
      const options = $(this).find('option');

      const errorClass = $(this).closest('.gfield:not(.gfield--type-address)').hasClass('gfield_error') ? 'error' : '';

      const label = $(this).siblings('label').length ? $(this).siblings('label') : $(this).closest('.gfield').find('label');
      const srInline = label.text().replaceAll('*','').replaceAll(':','')+' dropdown ';

      let customSelect = $('<div class="custom-select"><div class="custom-select__current '+errorClass+'" tabindex="0" data-sr="'+srInline+'"><span class="show-for-sr">'+srInline+'</span>'+options.eq(0).text()+'</div><div class="custom-select__list"></div></div>');

      options.each(function(index,option){
        let selectedText = '';
        if ($(option).hasClass('gf_placeholder')) { 
          customSelect.find('.custom-select__current').text($(option).text()); 
        } else {
          if ($(option).is(':selected')) { customSelect.find('.custom-select__current').html('<span class="show-for-sr">'+srInline+'</span>'+$(option).text()); selectedText = 'selected'; }
          customSelect.find('.custom-select__list').append('<div class="custom-select__option '+selectedText+'" tabindex="0" data-value="'+$(option).attr('value')+'">'+$(option).text()+'</div>');
        }
      });

      $(this).after(customSelect);
    });
  })

  $(document).on('gform_post_render', function () {
    $(window).trigger('custom-select-init'); 
  });

  $('.gform_wrapper').on('click','.custom-select__current', function(){
    $(this).siblings('.custom-select__list').slideToggle();
    $(this).closest('.custom-select').css('z-index', '11');
    $(this).closest('.gfield--type-select').siblings().find('.custom-select').css('z-index', '10');
  });

  $('.gform_wrapper').on('click','.custom-select__option', function(){
    $(this).addClass('selected').siblings().removeClass('selected');
    const srInline = $(this).closest('.custom-select').find('.custom-select__current').data('sr');
    $(this).closest('.custom-select').find('.custom-select__current').html('<span class="show-for-sr">'+srInline+'</span>'+$(this).text());
    $(this).closest('.custom-select').prev('select').val($(this).data('value')).trigger('change');
    $(this).closest('.custom-select__list').slideUp();
  });

  // keyboard accessibility
  $(document).on('keydown',function(e){
    // enter on custom select current
    if ($('.custom-select__current:focus').length && e.which == 13) {
      $('.custom-select__current:focus').trigger('click');
    }
    // down arrow on custom select current
    if ($('.custom-select__current:focus').length && e.which == 40) {
      e.preventDefault();
      const list = $('.custom-select__current:focus').siblings('.custom-select__list');
      list.slideDown();
      setTimeout(function(){
        list.find('.custom-select__option:not(:empty)').eq(0).focus();  
      }, 10);      
    }  
    // up arrow on custom select current
    if ($('.custom-select__current:focus').length && e.which == 38) {
      e.preventDefault();
      const list = $('.custom-select__current:focus').siblings('.custom-select__list');
      list.slideUp();     
    } 
    // enter on custom select option
    if ($('.custom-select__option:focus').length && e.which == 13) {
      $('.custom-select__option:focus').trigger('click').closest('.custom-select').find('.custom-select__current').focus();
    }
    // down arrow on custom select option
    if ($('.custom-select__option:focus').length && e.which == 40) {
      e.preventDefault();
      $('.custom-select__option:focus').next('.custom-select__option').focus();      
    }  
    // up arrow on custom select option
    if ($('.custom-select__option:focus').length && e.which == 38) {
      e.preventDefault();
      $('.custom-select__option:focus').prev('.custom-select__option').focus();   
    } 
    // escape on custom select option
    if ($('.custom-select__option:focus').length && e.which == 27) {
      $('.custom-select__option:focus').parent().slideUp();
      $('.custom-select__option:focus').closest('.custom-select').find('.custom-select__current').focus();
    } 
  });

  // close open custom selects on off-click
  $(document).on('click',function(e){
    if (!$(e.target).hasClass('.custom-select') && !$(e.target).closest('.custom-select').length && $('.custom-select__list:visible').length) {
      $('.custom-select__list:visible').slideUp();
    }
  })
}
