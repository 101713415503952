import '../third-party/slick';

export default () => {
  const mobileQuery = window.matchMedia('(max-width: 640px)');

  // Content Banner
  const contentBanners = $('.content-banner__row');  
  contentBanners.each(function(index, contentBanner){
    contentBanner = $(contentBanner);
    const contentBannerSpeed = contentBanner.data('speed');
    contentBanner.slick({
      accessibility: true,
      infinite: true,
      slidesToShow: 1,
      autoplay: true,
      autoplaySpeed: contentBannerSpeed,
      dots: true,
      pauseOnHover: true,
      focusOnSelect: true,
      dotsClass: 'carousel-row__indicators__row',
      appendArrows: contentBanner.siblings('.carousel-row__controls'),
      appendDots: contentBanner.siblings('.carousel-row__indicators'),
      prevArrow: '<button type="button" class="carousel-row__controls__control control--prev slick-prev" aria-label="Previous Slide"></button>',
      nextArrow: '<button type="button" class="carousel-row__controls__control control--next slick-next" aria-label="Next Slide"></button>',
    });
    contentBanner.on('afterChange',function(event){
      const section = $(event.target).closest('.section');

      if (section.find(':focus').length) {
        section.find('.slick-active').find('.carousel-row__slide').focus();
      }
    });
  })

  // Card Carousel
  const cardCarousels = $('.card-carousel__row');
  cardCarousels.each(function(index, cardCarousel){
    cardCarousel = $(cardCarousel);

    cardCarousel.slick({
      accessibility: true,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 3,
      autoplay: false,
      dots: true,
      pauseOnHover: true,
      focusOnSelect: true,
      dotsClass: 'carousel-row__indicators__row',
      appendArrows: cardCarousel.siblings('.carousel-row__controls'),
      appendDots: cardCarousel.siblings('.carousel-row__indicators'),
      prevArrow: '<button type="button" class="carousel-row__controls__control control--prev slick-prev" aria-label="Previous Slide"></button>',
      nextArrow: '<button type="button" class="carousel-row__controls__control control--next slick-next" aria-label="Next Slide"></button>',
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 640,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });

    cardCarousel.on('afterChange',function(event){
      const section = $(event.target).closest('.section');

      if (section.find(':focus').length) {
        section.find('.slick-slide.slick-active').first().find('.carousel-row__slide').focus();
      }
    });
  });

  // Reviews Carousel
  const reviewsCarousels = $('.reviews:not(.reviews--stacked) .reviews__row');
  reviewsCarousels.each(function(index, reviewsCarousel){
    reviewsCarousel = $(reviewsCarousel);
    const reviewsCarouselSpeed = reviewsCarousel.data('speed');
    const reviewsCarouselAuto = reviewsCarousel.data('autoplay');
    const reviewsInColumn = reviewsCarousel.closest('.wp-block-column').length;
    const slidesPerDesktop = reviewsInColumn ? 1 : 3;
    const slidesPerTablet = reviewsInColumn ? 1 : 2;
    reviewsCarousel.not('.slick-initialized').slick({
      accessibility: true,
      infinite: true,
      slidesToShow: slidesPerDesktop,
      slidesToScroll: slidesPerDesktop,
      autoplay: reviewsCarouselAuto,
      autoplaySpeed: reviewsCarouselSpeed,
      dots: true,
      pauseOnHover: true,
      focusOnSelect: true,
      dotsClass: 'carousel-row__indicators__row',
      appendArrows: reviewsCarousel.siblings('.carousel-row__controls'),
      appendDots: reviewsCarousel.siblings('.carousel-row__indicators'),
      prevArrow: '<button type="button" class="carousel-row__controls__control control--prev slick-prev" aria-label="Previous Slide"></button>',
      nextArrow: '<button type="button" class="carousel-row__controls__control control--next slick-next" aria-label="Next Slide"></button>',
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: slidesPerTablet,
            slidesToScroll: slidesPerTablet,
            dots: true,
          },
        },
        {
          breakpoint: 640,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });

    // Send focus to active slide
    reviewsCarousel.on('afterChange',function(event){
      const section = $(event.target).closest('.section.reviews');

      if (section.find(':focus').length) {
        section.find('.slick-slide.slick-active').first().find('.carousel-row__slide').focus();
      }
    });
  });

  // Testimonial Carousel
  const testimonialCarousels = $('.testimonial-carousel .testimonial-carousel__row');
  testimonialCarousels.each(function(index, testimonialCarousel){
    testimonialCarousel = $(testimonialCarousel);
    const testimonialCarouselSpeed = testimonialCarousel.data('speed');
    const testimonialCarouselAuto = testimonialCarousel.data('autoplay');
    const testimonialInColumn = testimonialCarousel.closest('.wp-block-column').length;
    const slidesPerDesktop = testimonialInColumn ? 1 : 1;
    const slidesPerTablet = testimonialInColumn ? 1 : 1;
    testimonialCarousel.not('.slick-initialized').slick({
      accessibility: true,
      infinite: true,
      slidesToShow: slidesPerDesktop,
      slidesToScroll: slidesPerDesktop,
      autoplay: testimonialCarouselAuto,
      autoplaySpeed: testimonialCarouselSpeed,
      dots: true,
      pauseOnHover: true,
      focusOnSelect: true,
      dotsClass: 'carousel-row__indicators__row',
      appendArrows: testimonialCarousel.siblings('.carousel-row__controls'),
      appendDots: testimonialCarousel.siblings('.carousel-row__indicators'),
      prevArrow: '<button type="button" class="carousel-row__controls__control control--prev slick-prev" aria-label="Previous Slide"></button>',
      nextArrow: '<button type="button" class="carousel-row__controls__control control--next slick-next" aria-label="Next Slide"></button>',
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: slidesPerTablet,
            slidesToScroll: slidesPerTablet,
            dots: true,
          },
        },
        {
          breakpoint: 640,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });

    // Send focus to active slide
    testimonialCarousel.on('afterChange',function(event){
      const section = $(event.target).closest('.section.testimonial-carousel');

      if (section.find(':focus').length) {
        section.find('.slick-slide.slick-active').first().find('.carousel-row__slide').focus();
      }
    });
  });

  // Team Carousel
  const teamCarousels = $('.team-carousel__row');
  teamCarousels.each(function(index, teamCarousel){
    teamCarousel = $(teamCarousel);
    teamCarousel.slick({
      accessibility: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      dots: true,
      focusOnSelect: true,
      dotsClass: 'carousel-row__indicators__row',
      appendArrows: teamCarousel.siblings('.team-carousel__controls').find('.carousel-row__controls'),
      appendDots: teamCarousel.siblings('.team-carousel__controls').find('.carousel-row__indicators'),
      prevArrow: '<button type="button" class="carousel-row__controls__control control--prev slick-prev" aria-label="Previous Slide"></button>',
      nextArrow: '<button type="button" class="carousel-row__controls__control control--next slick-next" aria-label="Next Slide"></button>',
    });

    teamCarousel.on('swipe',function(event){
      const bioToggle = $(event.target).closest('.section').find('.team-carousel__row').find('.bio__toggle');

      if( bioToggle.hasClass('expanded') ) {
        bioToggle.removeClass('expanded').prop('ariaExpanded', false).text('Read More +')
        .attr('aria-label', 'Collapse Bio').prev().find('.more-text').hide('fast');
      }
    });

    teamCarousel.on('afterChange',function(slick){
      const section = $(slick.target).closest('.section');
      const theme = section.find('.slick-active').find('.team-carousel__slide').data('theme');
      const controls = teamCarousel.siblings('.team-carousel__controls').find('.carousel-row__controls');
      const indicators = teamCarousel.siblings('.team-carousel__controls').find('.carousel-row__indicators');
      const themeClass = theme ? 'style--'+theme : 'style--light';

      if (!indicators.hasClass('no-styling')) {
        indicators.removeClass('style--dark style--light').addClass(themeClass);
      }
      controls.removeClass('style--dark style--light').addClass(themeClass);
    });
  });

  $(window).on('load',function(){
    $('.team-carousel__team .carousel-row__controls__control').on('click',function() {
      const bioToggle = $(this).closest('.team-carousel__controls').siblings('.team-carousel__row').find('.bio__toggle');
      if( bioToggle.hasClass('expanded') ) {
        bioToggle.removeClass('expanded').prop('ariaExpanded', false).text('Read More +')
        .attr('aria-label', 'Collapse Bio').prev().find('.more-text').hide('fast');
      }
    });

    $('.carousel-row__indicators__row li > button').on('keydown',function(e){
      if (e.which == 13) {
        const ref = $(this);
        
        setTimeout(function(){
          const indicatorIndex = ref.index();
          const readMore = ref.closest('.team-carousel__team').find('.team-carousel__row .slick-slide[data-slick-index="'+indicatorIndex+'"]').find('.team-carousel__slide__bio .bio__toggle');
          const ctas = ref.closest('.team-carousel__team').find('.team-carousel__row .slick-slide[data-slick-index="'+indicatorIndex+'"]').find('.team-carousel__slide__ctas .btn');

          if (readMore.length) {
            readMore.focus();
          } else {
            ctas.focus();
          }
        }, 10);
      }
    });
  });

  // expand/collapse descriptions on Team Carousel
  $('.bio__toggle').on('click',function(){
    if( !$(this).hasClass('expanded') ) {
      $(this).addClass('expanded').prop('ariaExpanded', true);
      $(this).attr('aria-label', 'Expand Bio');
      $(this).prev().find('.more-text').show('fast');
      $(this).text('Read Less -');
    } else {
      $(this).removeClass('expanded').prop('ariaExpanded', false);
      $(this).attr('aria-label', 'Collapse Bio');
      $(this).prev().find('.more-text').hide('fast');
      $(this).text('Read More +');
    }
  });
  
  // Header Banner
  const headerBanners = $('.home-banner__row');
  $(document).ready(function(){
    headerBanners.each(function(index, headerBanner){
      headerBanner = $(headerBanner);
      headerBanner.on('init',function(slick){
        // lockFocus(slick);
      });

      headerBanner.slick({
        accessibility: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        dots: true,
        focusOnSelect: true,
        dotsClass: 'carousel-row__indicators__row',
        appendArrows: headerBanner.siblings('.carousel-row__controls'),
        appendDots: headerBanner.siblings('.carousel-row__indicators'),
        prevArrow: '<button type="button" class="carousel-row__controls__control control--prev slick-prev" aria-label="Previous Slide"></button>',
        nextArrow: '<button type="button" class="carousel-row__controls__control control--next slick-next" aria-label="Next Slide"></button>',
      });

      headerBanner.on('afterChange',function(slick){
        const section = $(slick.target).closest('.section');
        const theme = section.find('.slick-active').find('.home-banner__slide ').data('theme');
        const controls = headerBanner.siblings('.carousel-row__controls');
        const indicators = headerBanner.siblings('.carousel-row__indicators');
        const themeClass = 'style--'+theme;

        if (!indicators.hasClass('no-styling')) {
          indicators.removeClass('style--dark style--light').addClass(themeClass);
        }
        controls.removeClass('style--dark style--light').addClass(themeClass);
        
        // lockFocus(slick);
      });

      const lastBtn = headerBanner.find('.slick-active .home-banner__slide__content .slide__ctas .btn').last();

      lastBtn.on('focusout',function(){
        
        setTimeout(function(){
          headerBanner.siblings('.carousel-row__controls').find('.control--next').focus();
        }, 10);
      });

    });
  });
  
  // Logo Carousel
  const logoCarousels = $('.logo-carousel__row'); 
  let newSize, oldSize; 
  $(window).on('load resize',function(){
    newSize = $(this).outerWidth();
    if (newSize != oldSize) {
      oldSize = newSize;
      logoCarouselInit();
    }
  });

  function logoCarouselInit() {
    logoCarousels.each(function(index, logoCarousel){   
      logoCarousel = $(logoCarousel);
      // set up slides
      let images = logoCarousel.find('.logo-carousel__slide');

      // Handle the .logo-carousel__logo-cards differently
      if (logoCarousel.hasClass('logo-carousel__cards')) {
        
        // If already initialized, destroy the current slider
        if (logoCarousel.hasClass('slick-initialized')) {
          logoCarousel.slick('unslick');
        }
        
        // Initialize the slider with 3 items per row
        logoCarousel.on('init',()=>{
          logoCarousel.closest('.logo-carousel').find('.carousel-row__indicators').toggleClass('many-items', logoCarousel.closest('.logo-carousel').find('.carousel-row__indicators li').length > 5);
        }).slick({
          accessibility: true,
          infinite: true,
          slidesToShow: 3,
          slidesToScroll: 1,
          autoplay: $(this).data('autoplay'),
          autoplaySpeed: logoCarousel.data('speed'),
          dots: true,
          pauseOnHover: true,
          dotsClass: 'carousel-row__indicators__row',
          appendArrows: logoCarousel.siblings('.carousel-row__controls'),
          appendDots: logoCarousel.siblings('.carousel-row__indicators'),
          prevArrow: '<button type="button" class="carousel-row__controls__control control--prev slick-prev" aria-label="Previous Slide"></button>',
          nextArrow: '<button type="button" class="carousel-row__controls__control control--next slick-next" aria-label="Next Slide"></button>',
          responsive: [
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 769,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
        });
      } else {

        // Default logo carousel logic
        if (logoCarousel.hasClass('slick-initialized')) {
          images = logoCarousel.find('.slick-slide:not(.slick-cloned) .logo-carousel__slide');
          const slides = images.detach();
          logoCarousel.slick('unslick');
          logoCarousel.empty();
          slides.appendTo(logoCarousel);
        } 
        let maxW = logoCarousel.outerWidth();
        let lastIndex = 0;
        images.each(function(index2, image){
          const thisW = $(image).find('img').outerWidth() + 32;
          maxW -= thisW;
          if (maxW < 0) {
            images.slice(lastIndex, index2).wrapAll('<div class="new-slide"></div>');
            lastIndex = index2;
            maxW = logoCarousel.outerWidth();
          }
        });
        if (images.length != lastIndex) {
          images.slice(lastIndex, images.length).wrapAll('<div class="new-slide"></div>');
        }
        // init slick
        const logoCarouselSpeed = logoCarousel.data('speed');
        logoCarousel.slick({
          accessibility: true,
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: $(this).data('autoplay'),
          autoplaySpeed: logoCarouselSpeed,
          dots: true,
          pauseOnHover: true,
          dotsClass: 'carousel-row__indicators__row',
          appendArrows: logoCarousel.siblings('.carousel-row__controls'),
          appendDots: logoCarousel.siblings('.carousel-row__indicators'),
          prevArrow: '<button type="button" class="carousel-row__controls__control control--prev slick-prev" aria-label="Previous Slide"></button>',
          nextArrow: '<button type="button" class="carousel-row__controls__control control--next slick-next" aria-label="Next Slide"></button>',
        });
      }

      logoCarousel.on('afterChange',function(event){
        const section = $(event.target).closest('.section');

        if (section.find(':focus').length) {
          section.find('.slick-active').find('a').eq(0).focus();
        }
      });
    });    
  }
  logoCarouselInit();

  // Routing Cards
  function mobileOnlySlider() {
    if(mobileQuery.matches) {
      if(!$('.routing-cards__row').hasClass('slick-initialized')){
        $('.routing-cards__row').each(function(){
          $(this).slick({
            accessibility: true,
            infinite: true,
            mobileFirst: true,
            dots: true,
            dotsClass: 'carousel-row__indicators__row',
            appendArrows: '.carousel-row__controls',
            appendDots: $(this).siblings('.carousel-row__indicators'),
            prevArrow: '<button type="button" class="carousel-row__controls__control control--prev slick-prev" aria-label="Previous Slide"></button>',
            nextArrow: '<button type="button" class="carousel-row__controls__control control--next slick-next" aria-label="Next Slide"></button>',
            slidesToShow: 1,
            slidesToScroll: 1,
          });
        });
      }
    } else {
      if($('.routing-cards__row').hasClass('slick-initialized')){
        $('.routing-cards__row').slick('unslick');
      }
    }
  }

  mobileOnlySlider();
  $(window).on('resize',mobileOnlySlider);

  // Lob Filter Slider
  const lobSliders = $('.rhc-offers__filter__row');
  lobSliders.each(function(index, lobSlider){
    lobSlider = $(lobSlider);
      lobSlider.slick({
        accessibility: true,
        infinite: false,
        slidesToShow: 5,
        slidesToScroll: 5,
        autoplay: false,
        dots: false,
        appendArrows: lobSlider.siblings('.carousel-row__controls'),
        prevArrow: '<button type="button" class="carousel-row__controls__control control--prev slick-prev" aria-label="Previous Slide"></button>',
        nextArrow: '<button type="button" class="carousel-row__controls__control control--next slick-next" aria-label="Next Slide"></button>',
      });
  });

  // Homepage Services Slider
  function hpServiceSlider() {
    if($('.hp-services__post-row').hasClass('slick-initialized')){
      $('.hp-services__post-row').slick('unslick');
    }

    const serviceSliders = $('.hp-services__post-row');
    serviceSliders.each(function(index, serviceSlider){
      serviceSlider = $(serviceSlider);
      serviceSlider.slick({
        accessibility: true,
        infinite: true,
        autoPlay: false,
        dots: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        dotsClass: 'carousel-row__indicators__row',
        appendArrows: serviceSlider.siblings('.carousel-row__controls'),
        prevArrow: '<button type="button" class="carousel-row__controls__control control--prev slick-prev" aria-label="Previous Slide"></button>',
        nextArrow: '<button type="button" class="carousel-row__controls__control control--next slick-next" aria-label="Next Slide"></button>',
        appendDots: serviceSlider.siblings('.carousel-row__indicators'),
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });
    });
  }

  hpServiceSlider();

  $(document).on('hpsChange',hpServiceSlider);

  $(document).on('resize',hpServiceSlider);

  // Play/Pause button
  $('.carousel-row__button').on('click',function() {

    const carousel = $(this).closest('.carousel-row__indicators').siblings('.carousel-row');
    if (!$(this).hasClass('click-toggle')) {
      $(this).addClass('click-toggle');
      $(this).attr('aria-label','Play Carousel');
      $(this).addClass('paused');
      $(this).addClass('pressed');
      $(this).closest('.carousel-row__indicators').addClass('paused');
      carousel.addClass('paused').slick('slickPause');
    } else {
      $(this).removeClass('click-toggle');
      $(this).attr('aria-label','Pause Carousel');
      $(this).removeClass('paused');
      $(this).removeClass('pressed');
      $(this).closest('.carousel-row__indicators').removeClass('paused');
      carousel.removeClass('paused').slick('slickPlay');
    }
  });

  // Trigger Pause on hover
  $(document).on('mouseenter','.carousel-row .slick-slide',function() {
    playPauseCarousel($(this).closest('section'), 'pause');
  }).on('mouseleave','.carousel-row .slick-slide',function() {
    playPauseCarousel($(this).closest('section'), 'play');
  });

  // Trigger Pause on focus of elements
  $(document).on('focus','.carousel-row__controls__control, .carousel-row__indicators button',function() {
    playPauseCarousel($(this).closest('section'), 'pause');
  }).on('blur','.carousel-row__controls__control, .carousel-row__indicators button',function() {
    playPauseCarousel($(this).closest('section'), 'play');
  });

  // Pause function
  function playPauseCarousel(carousel, state = 'pause') {
    const indicatorBtn = carousel.find('.carousel-row__button');

    if (state == 'pause' && !indicatorBtn.hasClass('paused')) {
      indicatorBtn.addClass('paused');
      indicatorBtn.closest('.carousel-row__indicators').addClass('paused');
      carousel.find('.carousel-row__indicators').addClass('paused');
      carousel.find('.carousel-row.autoplay-on').slick('slickPause');
    }

    if (state == 'play' && !indicatorBtn.hasClass('pressed')) {
      indicatorBtn.removeClass('paused');
      indicatorBtn.closest('.carousel-row__indicators').removeClass('paused');
      carousel.find('.carousel-row.autoplay-on').siblings('.carousel-row__indicators').removeClass('paused');
      carousel.find('.carousel-row.autoplay-on').slick('slickPlay');
    }
  }

  // Trigger Pause on arrow interaction
  $(document).on('click','.carousel-row__controls__control',function() {
    const indicatorBtn = $(this).closest('.carousel-row__controls').siblings('.carousel-row__indicators').find('.carousel-row__button');
    if (!indicatorBtn.hasClass('paused')) {
      indicatorBtn.addClass('paused');
      indicatorBtn.addClass('pressed');
      indicatorBtn.closest('.carousel-row__indicators').addClass('paused');
      $(this).closest('.carousel-row__controls').siblings('.carousel-row').addClass('paused').slick('slickPause');
    }
  });
}
