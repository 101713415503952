export default () => {
  $(window).on('load scroll', function(){
    const pageHeight = $(document).height();
    const quarterWay = pageHeight * 0.25;

    if ($(this).scrollTop() > quarterWay) {
      $('#back-to-top').fadeIn();
      $('#back-to-top').css('display', 'flex');
    } else {
      $('#back-to-top').fadeOut();
    }
  });

  $('#back-to-top').on('click', function(){
    $('html, body').scrollTop(0);
    setTimeout(function(){
      $('#main').trigger('focus');
    }, 400);
  });
}