export default () => {
  $('.tooltip__container').on('mouseenter focus', function () {    
    const tooltip = $(this).find('.tooltip__container__info');
    tooltip.removeClass('bottom right centered').attr('style','');
    const offsetRight = ($(window).width() - (tooltip.offset().left + tooltip.outerWidth()));

    const notEnoughRightSpace = offsetRight < 0;

    tooltip.removeClass('disabled').toggleClass('right', notEnoughRightSpace);

    const notEnoughLeftSpace = tooltip.offset().left < 0;

    if (notEnoughLeftSpace) {
      const leftOffset = -$(this).offset().left + 15;
      tooltip.css({ right: 'auto', left: leftOffset })
    }
  })

  $('.tooltip__container').on('mouseleave blur', function () {
    const tooltip = $(this).find('.tooltip__container__info');
    tooltip.removeClass('bottom right centered').attr('style','');
  })

  $('.tooltip__container__info__close-btn').on('click', function () {
    $('.tooltip__container__info:visible').addClass('disabled')
  })
}
