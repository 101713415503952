export default () => {
  // Accordion functionality
  $('.accordion__container__item > button').on('click', function () {
    const btn = $(this);
    const parent = $(this).parent();
    const icon = btn.find('.accordion__container__item__title__img');
    icon.toggleClass('open');
    
    // Hide/Show accordion item
    parent.find('> .accordion__container__item__text').slideToggle();
    
    // Open Logic
    const siblings = parent.siblings().find('button');

    if (parent.hasClass('open--Single')) {
      parent.siblings().find('.accordion__container__item__text').slideUp();
      siblings.find('.accordion__container__item__title__img').removeClass('open');
    } 

    parent.siblings().each(function () {
      const btn = $(this).find('> button');
      const siblings = $(this).siblings().find('> button');

      if (btn.find('.accordion__container__item__title__img').hasClass('open')) {
        btn.attr('aria-expanded', 'true');
        siblings.attr('aria-expanded', 'false');
      } else {
        btn.attr('aria-expanded', 'false');
      }
    })
  })
}
