import loadGoogle from '../util/loadGoogle';

/* eslint-disable */
export default () => {
  $(document).on('gform_post_render', function (event, form_id, current_page) {
    // we wrap all of this in a loadGoogle call so that it acts as the callback once Google is loaded async
    loadGoogle(function(){
      // remove autocomplete from address search fields for Google Autocomplete
      $('.ginput_container_address input').attr('autocomplete','none');
      $('.ginput_container_address select').attr('autocomplete','none');
      setTimeout(function(){
        $('.ginput_container_address input').attr('autocomplete','none');
        $('.ginput_container_address select').attr('autocomplete','none');
      }, 200);

      const inputs = $('.gfield--type-address .ginput_container_address span:first-child').find('input');

      inputs.each(function(index, input){
        const inCanada = $(input).closest('fieldset').find('input[value="Canada"]').length
        const options = inCanada ? {
          componentRestrictions: { country: "ca" },
        } : {};
        const autocomplete = new google.maps.places.Autocomplete(input, options);
        autocomplete.setFields(['address_components']);
        
        let address_num, address_street, address_secondary, address_city, address_province, address_postal, address_country;
        autocomplete.addListener('place_changed', function() {
          const place = autocomplete.getPlace();

          $.each(place.address_components,function(index, item) {
            if (item.types.includes('street_number')) {
              address_num = item.long_name;
            }
            if (item.types.includes('route')) {
              address_street = item.long_name;
            }
            if (item.types.includes('subpremise')) {
              address_secondary = item.long_name;
            }
            if (item.types.includes('locality')) {
              address_city = item.long_name;
            }
            if (item.types.includes('administrative_area_level_1')) {
              address_province = item.long_name;
            }
            if (item.types.includes('postal_code_prefix') || item.types.includes('postal_code')) {
              address_postal = item.long_name;
            }
            if (item.types.includes('country')) {
              address_country = item.long_name;
            }
          });

          const address_field = $(input).closest('.ginput_container_address');
          address_field.find(input).eq(0).val(address_num + ' ' + address_street).trigger('change');
          address_field.find('.address_line_2 input').val(address_secondary).trigger('change');
          address_field.find('.address_city input').val(address_city).trigger('change');
          address_field.find('.address_state input').val(address_province).trigger('change');
          address_field.find('.address_zip input').val(address_postal).trigger('change');
          // address_field.find('.address_country select').val(address_country).trigger('change');
          address_field.find('.address_country .custom-select__option[data-value="'+address_country+'"]').trigger('click');
          // address_field.find('.address_state select').val(address_province).trigger('change');
          address_field.find('.address_state .custom-select__option[data-value="'+address_province+'"]').trigger('click');
        });
      });
    });
  });
}
