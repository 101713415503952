import '../styles/main.scss';

// import external dependencies
import jquery from 'jquery';
import 'foundation-sites/js/entries/foundation'
import 'foundation-sites/js/entries/foundation-plugins'

// import local dependencies
import Router from './util/Router';
import common from './routes/common';

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
});

window.jQuery = jquery;
window.$ = jquery;

// Load Events
jQuery(document).ready($ => {
  $(document).foundation();
  routes.loadEvents();
});
