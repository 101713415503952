export default () => {

  //Show Profile Bio
  $('.profile-card__toggle').on('click', function() {
    var block = $(this).closest('.profile-cards');
    var item = $(this).closest('.profile-card');

    // Handle 'expanded' attributes and 'open' class
    $(this).attr('aria-expanded', function (i, attr) {
      return attr == 'true' ? 'false' : 'true';
    });
    item.toggleClass('open').siblings().removeClass('open');

    // Find respective details panel and remove cloned panel if exists already, reset margin-bottom on profile card
    const itemID = item.data('card-id');
    const itemInfo = block.find('.profile-card__details .profile-card__details__panel[data-panel-id = '+itemID+']');
    block.find('.profile-cards__cards-row .profile-card__details__panel').remove();
    block.find('.profile-card').css('margin-bottom', 0);

    // Set height of the panel as margin-bottom of profile card. Clone respective details panel and adjust its position under the profile card.
    if(item.hasClass('open')) {
      var height = itemInfo.outerHeight() - 2;
      item.css('margin-bottom', height+'px');
      block.find('.profile-cards__cards-row').append(itemInfo.clone());
      var info = block.find('.profile-cards__cards-row .profile-card__details__panel');
      var cardHeight = item.find('.profile-card__toggle').outerHeight();
      var pos = item.position().top+cardHeight;
      info.css('top', pos+'px').show();

      // Direct focus to close button inside panel
      info.find('.panel__toggle.expand-toggle').focus();
    }
  });

  // Handle the close button within the details panel
  $('.profile-cards__cards-row').on('click', '.panel__toggle', function() {
    const panel = $(this).closest('.profile-card__details__panel');

    panel.siblings('.profile-card.open').removeClass('open').css('margin-bottom', 0)
    .find('.profile-card__toggle').attr('aria-expanded', 'false');
    panel.remove();
  });

  // Handle keydown for profile-card__toggle
  $('.profile-card__toggle').on('keydown', function(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      $(this).trigger('click');
    }
  });

  // Handle keydown for panel__toggle
  $('.profile-cards__cards-row').on('keydown', '.panel__toggle.expand-toggle', function(event) {
    if (event.key === 'Enter') {
      const block = $(this).closest('.profile-cards');
      const panel = $(this).closest('.profile-card__details__panel');
      const panelID = panel.data('panel-id');
      const card = block.find('.profile-cards__cards-row .profile-card[data-card-id = '+panelID+']');

      event.preventDefault();
      // Return focus to the respective .profile-card__toggle element
      card.find('.profile-card__toggle').focus();
      $(this).trigger('click');
    }
  });
}