import lazyLoad from '../util/lazyLoad';
import smoothScroll from '../util/smooth-scroll';
import smoothScrollToHash from '../util/hash-scroll';
import contentAnchoringNav from '../blocks/content-anchoring-nav';
import carousel from '../components/carousel';
import headerCollapsibleText from '../blocks/header-collapsible-text';
import accordion from '../blocks/accordion';
import header from '../components/header';
import customSelects from '../components/customSelects';
import footer from '../components/footer';
import equalizer from '../components/equalizer';
import backToTopBtn from '../components/back-to-top-btn';
import tooltips from '../components/tooltips';
import video from '../components/video';
import search from '../components/search';
import siteHeaderSearch from '../components/site-header-search';
import rhcTabs from '../blocks/rhc-tabs';
import addressAutocomplete from '../components/address-autocomplete';
import outputPhone from '../components/output-phone';
import shareIcons from '../components/share-icons';
import gravityForms from '../components/gravity-forms';
import iconFeatList from '../blocks/icon-feature-list';
import profileCards from '../blocks/profile-cards';
import routingCards from '../blocks/routing-cards';
import logoCarousel from '../blocks/logo-carousel';

export default {
  init() {
    // lazy loading images
    lazyLoad();
    // smooth scroll on internal link click
    smoothScroll('a[href*=\\#]');
    // Handle smooth scroll for loading hash
    smoothScrollToHash();

    // block-based
    if($('.anchor-list').length) {
      contentAnchoringNav();
    }
    if ($('.accordion').length) {
      accordion();
    }
    if ($('.header-collapsible-text').length) {
      headerCollapsibleText();
    }
    // components
    if ($('.header').length) {
      header();
    }
    if ($('.carousel-row, .card-carousel__row, .rhc-offers__filter, .logo-carousel, .testimonial-carousel').length) {
      carousel();
    }
    if ($('.footer').length) {
      footer();
    }
    if ($('.equalizer-item').length) {
      equalizer();
    }
    if ($('#back-to-top').length) {
      backToTopBtn();
    }
    if ($('.tooltip').length) {
      tooltips();
    }
    if ($('.video').length) {
      video();
    }

    if ($('.search-results').length) {
      search();
    }
    if ($('.header__utility-nav__search').length) {
      siteHeaderSearch();
    }
    if ($('.rhc-tabs').length || $('.rebates').length) {
      rhcTabs();
    }
    if ($('.gform_wrapper').length) {
      customSelects();
      addressAutocomplete();
    }
    if ($('.phone-number').length) {
      outputPhone();
    }
    if ($('.share-icons').length) {
      shareIcons();
    }
    if ($('.gform_wrapper').length) {
      gravityForms();
    }
    if ($('.icon-feat-list').length) {
      iconFeatList();
    }
    if ($('.profile-cards').length) {
      profileCards();
    }
    if ($('.routing-cards').length) {
      routingCards();
    }
    if ($('.logo-carousel.layout--cards').length) {
      logoCarousel();
    }
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
