export default () => {
  $(window).on('load resize',()=>{
    var isMobile = window.matchMedia("(max-width: 639px)");
    if (isMobile.matches) {
      $('.item__toggle').each(function() {
        if ($(this).attr('aria-expanded') == 'true') {
          $(this).closest('.icon-feat-list__item').find('.item__content .item__content__text').attr('aria-hidden', false);
        } else {
          $(this).closest('.icon-feat-list__item').find('.item__content .item__content__text').attr('aria-hidden', true);
        }
      });
    } else {
      $('.item__content__text').each(function() {
        $(this).removeAttr('aria-hidden');
      });
    }
  });

  $('.item__toggle').on('click', function() {
      const accordionToggle = $(this);
      const accordionItem = $(this).closest('.icon-feat-list__item');
      const accordionContent = accordionItem.find('.item__content .item__content__text');

      if (!accordionItem.hasClass('open')) {
          accordionToggle.attr('aria-expanded', true);
          accordionItem.addClass('open');
          accordionContent.slideDown().attr('aria-hidden', false);
      } else {
          accordionItem.removeClass('open');
          accordionToggle.attr('aria-expanded', false);
          accordionContent.slideUp().attr('aria-hidden', true);
      }
  });
}