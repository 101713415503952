/* Smooth Scroll */

export default function smoothScroll(selector) {
  $(selector).click(function (event) {
    const scrollTo = $(this.hash);
    if (scrollTo.length) {
      event.preventDefault();
      scrollToElement(scrollTo);
      scrollTo.focus();
    }
  });
}

export const scrollToElement = function (element) {
  // const headerHeight = $('header').eq(0).outerHeight();
  const headerHeight = 20; // fixed constant for now until we add fixed header in

  if(element.length) {
    $('html, body').animate({ scrollTop: element.offset().top - headerHeight },500);
  }
}
