/* Smooth scroll to a specific hash */
import {scrollToElement} from './smooth-scroll';

export default function smoothScrollToHash() {
  const hash = window.location.hash.replace('/','');
  if (hash) {
    const scrollTo = $(hash);
    scrollToElement(scrollTo);
  }
}
