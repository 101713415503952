export default () => {
  const $trigger = $('.footer__accordion__toggle > button');
  $trigger.each(function(){
    var state = 'closed';

    $(this).on('click',function(){
      const content = $(this).closest('.footer__accordion__toggle').siblings('.footer__accordion__content');
      content.slideToggle(300);
      if(state === 'closed') {
        state = 'open';
        content.addClass('open');
        $(this).closest('.footer__accordion__toggle').addClass('open');
        $(this).attr('aria-expanded', 'true');
      } else {
        state = 'closed';
        content.removeClass('open');
        $(this).closest('.footer__accordion__toggle').removeClass('open');
        $(this).attr('aria-expanded', 'false');
      }
    });
  });

}