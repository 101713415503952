export default () => {
  // focus the post count on page load
  $('.search-results__inner__results__post-count:visible').eq(0).focus();

  const lobCheckboxes = document.querySelectorAll('input[name="lob[]"');
  const prod_serviceCheckBoxes = document.querySelectorAll('input[name="prod_service[]"]')

  const injected_offer_desktop = $('.search-results__inner__results__posts__injected-offers.desktop');
  const injected_offer_mobile = $('.search-results__inner__results__posts__injected-offers.mobile');

  if (injected_offer_desktop) {
    const searchResultsDesktop = $('.search-results__inner__results.show-for-large .search-results__inner__results__posts__post');
    const totalPostsDesktop = searchResultsDesktop.length;

    searchResultsDesktop.each(function (index) {
      if (index == Math.floor(totalPostsDesktop / 2 )) {
        injected_offer_desktop.prependTo($(this)); 
      }
    })
  }

  if (injected_offer_mobile) {
    const searchResultsMobile = $('.search-results__inner__results.hide-for-large .search-results__inner__results__posts__post');
    const totalPostsMobile = searchResultsMobile.length;

    searchResultsMobile.each(function (index) {
      if (index == Math.floor(totalPostsMobile / 2 )) {
        injected_offer_mobile.prependTo($(this)); 
      }
    })
  }

  // Mobile Filter by Category & Product Btns
  const category_filter_btn = $('.search-results__inner__filters__filter-btns__filter-by-category');
  const product_filter_btn = $('.search-results__inner__filters__filter-btns__filter-by-product');

  // Toggle filter by category buttons and states mobile
  $('.search-results__inner__filters__filter-btns__filter-by-category').on('click', function () {
    $('.search-results__inner__filters__lobs-container').toggleClass('active');
    $('.search-results__inner__filters__filter-btns__filter-by-category').toggleClass('active');
    $('.search-results__inner__filters__filter-btns__apply-filters-mobile__categories').toggle();
    $('.search-results__inner__filters__filter-btns__clear-filters-mobile__categories').toggle();
  })

  // Toggle filter by product buttons and states mobile
  $('.search-results__inner__filters__filter-btns__filter-by-product').on('click', function () {
    $('.search-results__inner__filters__products-container').toggleClass('active');
    $('.search-results__inner__filters__filter-btns__filter-by-product').toggleClass('active');
    $('.search-results__inner__filters__filter-btns__apply-filters-mobile__products').toggle();
    $('.search-results__inner__filters__filter-btns__clear-filters-mobile__products').toggle();
  })

  // Hide mobile filter buttons on document mouseup
  $(document).on('mouseup', function(e) {
    const products = $('.search-results__inner__filters__products-container, .search-results__inner__filters__products-container *');
    const categories = $('.search-results__inner__filters__lobs-container, .search-results__inner__filters__lobs-container *');

    if (!product_filter_btn.is(e.target) && product_filter_btn.has(e.target).length === 0 && !products.is(e.target)) {
      $('.search-results__inner__filters__products-container').removeClass('active');
      $('.search-results__inner__filters__filter-btns__filter-by-product').removeClass('active');
      $('.search-results__inner__filters__filter-btns__apply-filters-mobile__products').hide();
      $('.search-results__inner__filters__filter-btns__clear-filters-mobile__products').hide();
    }

    if (!category_filter_btn.is(e.target) && category_filter_btn.has(e.target).length === 0 && !categories.is(e.target)) {
      $('.search-results__inner__filters__lobs-container').removeClass('active');
      $('.search-results__inner__filters__filter-btns__filter-by-category').removeClass('active');
      $('.search-results__inner__filters__filter-btns__apply-filters-mobile__categories').hide();
      $('.search-results__inner__filters__filter-btns__clear-filters-mobile__categories').hide();
    }
  });

  // Function to remove only one lob taxonomy value from params since they have the same key
  URLSearchParams.prototype.remove = function(key, value) {
    const entries = this.getAll(key);
    const newEntries = entries.filter(entry => entry !== value);
    this.delete(key);
    newEntries.forEach(newEntry => this.append(key, newEntry));
  }

  lobCheckboxes.forEach(function(checkbox) {
    checkbox.addEventListener('change', function() {
      const searchParams = new URLSearchParams(window.location.search);

      if (this.checked) {
        searchParams.append('lob[]', this.value);
      } else {
        searchParams.remove('lob[]', this.value);
      }

      const newUrl = window.location.pathname + '?' + searchParams.toString();
      history.pushState({}, '', newUrl);
    });
  });

  // Change query string for Prod / Service Type Taxonomy
  prod_serviceCheckBoxes.forEach(function(checkbox) {
    checkbox.addEventListener('change', function() {
      const searchParams = new URLSearchParams(window.location.search);

      if (this.checked) {
        searchParams.append('prod_service[]', this.value);
      } else {
        searchParams.remove('prod_service[]', this.value);
      }

      const newUrl = window.location.pathname + '?' + searchParams.toString();
      history.pushState({}, '', newUrl);
    });
  });

  // Redirect to page 1 when Apply Filters is selected
  $('.search-results__inner__filters__filter-btns__apply-filters').on('click', function() {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete('paged');

    const searchTerm = $('#s-search-landing').val();
    const currentURL = window.location.href;  // Get the current URL
    const urlParts = currentURL.split('?');  // Split the URL into two parts: before and after the question mark
    const urlBase = urlParts[0].substring(0, urlParts[0].lastIndexOf('/'));

    const newUrl = urlBase + '/' + searchTerm + '?' + searchParams.toString();
    console.log(urlParts[0], urlBase, searchTerm, searchParams.toString(), newUrl);
    history.pushState({}, '', newUrl);
  }); 

  // Clear All Filters
  $('.search-results__inner__filters__filter-btns__clear-filters, .search-results__inner__filters__filter-btns__clear-filters-mobile__products, .search-results__inner__filters__filter-btns__clear-filters-mobile__categories').on('click', function() {
    const searchParams = new URLSearchParams(window.location.search);
    const urlParams = new URLSearchParams(searchParams);

    searchParams.delete('lob[]');
    searchParams.delete('prod_service[]');

    // Delete LOB search param(s) when not not on page one
    if (searchParams.get('paged')) {
      urlParams.forEach((value, key) => {
        if (key.startsWith('lob[') && key.endsWith(']')) {
          const prefix = 'lob[';
          const suffix = ']';
          const index = key.substring(4, key.length - 1); // Extract the index
  
          searchParams.delete(`${prefix+index+suffix}`);
          searchParams.delete('paged');
        }
      });
    }

    // Delete Prod / Service search param(s) when not not page one
    if (searchParams.get('paged')) {
      urlParams.forEach((value, key) => {
        if (key.startsWith('prod_service[') && key.endsWith(']')) {
          const prefix = 'prod_service[';
          const suffix = ']';
          const index = key.substring(4, key.length - 1); // Extract the index
  
          searchParams.delete(`${prefix+index+suffix}`);
          searchParams.delete('paged');
        }
      });
    }

    const newUrl = window.location.pathname + '?' + searchParams.toString();
    history.pushState({}, '', newUrl);
  });

  // select child checkboxes when parent is selected
  $('.search-results__inner__filters__products-container__products__parent-term input, search-results__inner__filters__lobs-container__lobs__parent-term input').on('change',function(){
    $(this).closest('div').siblings().find('input').prop('checked',$(this).is(':checked')).trigger('change')[0].dispatchEvent(new Event('change'));
  });
}
