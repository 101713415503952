export default () => {
  $('.hamburger').on('click', function () {
    $(this).toggleClass('active'); 
    $('.anchor-list__container').slideToggle();
    $(this).attr('aria-expanded', function (i, attr) {
      return attr == 'false' ? 'true' : 'false';
    }
  )})

  $('.anchor-list__large-menu__list__container__li').on('click', function () {
    $(this).addClass('active').siblings().removeClass('active');
  })

  // $('.anchor-list__container__item').on('click', function () {
  //   $('.anchor-list__container').slideToggle();
  //   $('.hamburger').removeClass('active');
  // })
}