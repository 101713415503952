export default () => {
  $('.header-collapsible-text__container__read-more').on('click', function() {
    var trigger = $(this);
    $('.header-collapsible-text__container__collapsible-text').slideDown();
    trigger.attr('aria-expanded', 'true');
    setTimeout(function(){
      trigger.hide();
    }, 100);
    $('.header-collapsible-text__container__read-less').css('display', 'flex');
  })

  $('.header-collapsible-text__container__read-less').on('click', function() {
    var trigger = $(this);
    $('.header-collapsible-text__container__collapsible-text').slideUp();    
    $('.header-collapsible-text__container__read-more').attr('aria-expanded', 'false');
    trigger.attr('aria-expanded', 'false');
    setTimeout(function(){
      trigger.hide();
    })
    $('.header-collapsible-text__container__read-more').css('display', 'flex').focus();
  })
}