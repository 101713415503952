export default () => { 
  const tabBlocks = $('.rhc-tabs');

  tabBlocks.each(function(){
    const tab       = $(this);
    const tabTitles = tab.find('.rhc-tabs__list__tab__title');
    const tabs      = tab.find('.rhc-tabs__tab');
    const tabList   = tab.find('.rhc-tabs__list .rhc-tabs__list__tab');

    // add base accessibility
    tab.find('.rhc-tabs__holder .rhc-tabs__tab').attr('role', 'tabpanel').attr('tabindex','-1');

    // If there is a hash representing the tab, show that tab, otherwise show first tab item on page load
    let tabIndexStart = 1;
    if(window.location.hash) {
      const hash = window.location.hash.substring(1).replace('/','');
      tab.find('.rhc-tabs__list__tab').each(function(){
        const label = $(this).text().toLowerCase().trim().replaceAll(' ','-');
        if (label == hash) {
          tabIndexStart = $(this).prevAll().length + 1;
        }
      });
      if (tabIndexStart != 1) {
        tab.find('.rhc-tabs__tab').hide().eq(tabIndexStart - 1).css('display', 'block');
      }
    }

    tab.find('.rhc-tabs__holder .rhc-tabs__tab:nth-child('+tabIndexStart+')').attr('aria-expanded', 'true');
    tab.find(`
      .rhc-tabs__list__tab:nth-child(${tabIndexStart}),
      .rhc-tabs__list__tab:nth-child(${tabIndexStart}) i, 
      .rhc-tabs__list__tab:nth-child(${tabIndexStart}) .rhc-tabs__list__tab__title`).addClass('active');

    // Prepend tab titles to tabs container and add accessibility features
    tabs.each(function(i){
      $(this).prepend(tabTitles.eq(i).clone());
      $(this).attr('id',tabList.eq(i).attr('aria-controls'));
    });

    // Create container div for tab holder mobile
    tab.find('.rhc-tabs__holder').each(function(){
      const newId = 'tabMobile--' + Math.random().toString(36).substr(2); // Tabs Mobile Unique ID's
      const tabHolderMobile = $(this).clone().removeClass().addClass('rhc-tabs__holder__mobile').attr('id', newId);
      // Insert tab holder mobile after tab Holder desktop
      $(this).after(tabHolderMobile);      
    });

    // Add classes and accessibility to mobile tabs
    tab.find('.rhc-tabs__holder__mobile .rhc-tabs__tab').attr('role', 'tabpanel').attr('tabindex','-1');
    tab.find('.rhc-tabs__holder__mobile .rhc-tabs__tab').removeClass().addClass('rhc-tabs__tab__mobile').show();
    tab.find('.rhc-tabs__tab__mobile:nth-child('+tabIndexStart+') > :first-child').addClass('active').find('i').addClass('active');
  });

  // Show and hide tabs on desktop via click/keyboard
  $('.rhc-tabs__list__tab').on('click keydown', function (e) {
    if (e.type == 'keydown' && e.which != 13) { return; }

    const index = $(this).prevAll().length;
    const tabsContainer = $(this).closest('.column').find('.rhc-tabs__holder');
    tabsContainer.find('.rhc-tabs__tab').hide().eq(index).css('display', 'block');

    tabsContainer.find('.rhc-tabs__tab').eq(index).focus({ preventScroll: true });
    tabsContainer.find('.rhc-tabs__tab').eq(index).attr('aria-expanded', function (i, attr) { return attr == 'true' ? 'false' : 'true' });
    tabsContainer.find('.rhc-tabs__tab').eq(index).siblings().attr('aria-expanded', 'false');

    $(this).addClass('active');
    $(this).find('.rhc-tabs__list__tab__title').addClass('active');
    $(this).find('i').addClass('active');

    $(this).siblings().removeClass('active');
    $(this).siblings().find('.rhc-tabs__list__tab__title').removeClass('active');
    $(this).siblings().find('i').removeClass('active');
  });

  // Show and hide via tabcordion for mobile via click
  $('.rhc-tabs__tab__mobile .rhc-tabs__list__tab__title').on('click', function() {
    const tabContent = $(this).closest('.rhc-tabs__tab__mobile').find('> *').not( ':first-child' );
    const tabSiblings = $(this).closest('.rhc-tabs__tab__mobile').siblings();

    tabContent.slideToggle();

    $(this).toggleClass('active');
    $(this).find('i').toggleClass('active');
    $(this).closest('.rhc-tabs__tab__mobile').attr('aria-expanded', function (i, attr) { return attr == 'true' ? 'false' : 'true' })

    tabSiblings.children().not( ':first-child' ).slideUp();
    tabSiblings.find('.rhc-tabs__list__tab__title').removeClass('active');
    tabSiblings.attr('aria-expanded', 'false');
  }) 

  // Move focus between tabs with arrow keys
  $('.rhc-tabs__list__tab').on('keydown',function(event) {
    // Check if the arrow key was pressed
    if (event.key === 'ArrowRight' || event.key === 'ArrowLeft') {
      // Get the currently focused tab
      const currentTab = $(':focus');
  
      // Move the focus to the next or previous tab
      if (event.key === 'ArrowRight') {
        currentTab.next().focus();
      } else if (event.key === 'ArrowLeft') {
        currentTab.prev().focus();
      }

      // Prevent the default action of the arrow keys
      event.preventDefault();
    }
  });

  // toggle visibility function
  function toggleTabVisibility() {
    if (window.matchMedia('(max-width: 640px)').matches) {
      $('.rhc-tabs .rhc-tabs__tab__mobile > .active:first-child').siblings().slideDown();
    }
  }

  $(window).on('resize', toggleTabVisibility);
  toggleTabVisibility();
}