export default () => {
  const $temp = $('<input>');
  const $url = $(location).attr('href');

  $('.share-icons__clipboard').on('click', function() {
    $('body').append($temp);
    $temp.val($url).select();
    document.execCommand('copy');
    $temp.remove();

    $(this).append('<div class="clipboard-message new">Link copied!</div>');
    $(this).find('.clipboard-message.new').removeClass('new').animate({ bottom: '100%' }, 900, function(){
    $(this).remove();
      $(this).remove();
    });
  });
}