export default () => {
  const tabletQuery = window.matchMedia('(min-width: 641px)');

  $(window).on('resize load equalizer', function() {
    equalizerRow();
  });

  $(document).on('focus blur','.routing-cards__card .equalizer-item-2 > a',function() {
    equalizerRow();
  });

  $(document).on('mouseenter mouseleave','.routing-cards__card', function() {
    equalizerRow();
  });

  // Equalizer
  function equalizerRow() {
    $('.equalizer-row').each(function(){
      const items = $(this).find('.equalizer-item');
      const items2 = $(this).find('.equalizer-item-2');

      if (tabletQuery.matches) {
        // first item
        let maxHeight = 0;
        items.css('height','auto');                
        items.each(function(){
          var thisHeight = $(this).height();
          if (thisHeight > maxHeight) {
            maxHeight = thisHeight;
          }
        });
        items.css('height',maxHeight+'px');

        // second item
        maxHeight = 0;
        items2.css('height','auto');
        items2.each(function(){
          var thisHeight = $(this).height();
          if (thisHeight > maxHeight) {
            maxHeight = thisHeight;
          }
        });
        items2.css('height',maxHeight+'px');

      } else {
        items.css('height','auto');
        items2.css('height','auto');
      }
    });
  }

  equalizerRow();
}