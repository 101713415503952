import {enableScroll, disableScroll} from '../util/preventScroll';

export default () => {
  // Viewport size
  const desktopQuery = window.matchMedia('(min-width: 1024px)');
  const tabletQuery = window.matchMedia('(max-width: 1023px)');

  // fixed header menu
  $(window).on('load scroll resize',function(){
    const winT = $(this).scrollTop();
    const header = $('.header');
    const hTopBar = header.find('.header__top-bar.top-bar--desktop');
    const hWrap = header.find('.header__wrap');
    const hInner = header.find('.header__inner');
    const hSpacer = header.find('.header__spacer');
    const triggerSpot = hInner.closest('.column').offset().top;

    if (winT >= triggerSpot) {
      if (!hWrap.hasClass('fixed')) {
        if(desktopQuery.matches) {
          hSpacer.css('height',hWrap.height()+hTopBar.height());        
          hWrap.addClass('fixed');
          hTopBar.addClass('fixed');
        } else {
          hSpacer.css('height',hWrap.height());        
          hWrap.addClass('fixed');
          hTopBar.addClass('fixed');
        }
      }
    } else {
      if (hWrap.hasClass('fixed')) {
        hWrap.removeClass('fixed');
        hSpacer.css('height','auto');
        hTopBar.removeClass('fixed');
      }
    }
  });

  // ensuring mobile menu looks correct on mobile load
  $(window).on('load resize',function(){
    if(desktopQuery.matches) {

      $('.header__menu').removeClass('open')
      .find('.sub-menu').removeClass('open')
      .siblings('.menu-item__link').removeClass('open');

    } else {
      const winH =$(window).height();
      const headerH = $('.header__top').outerHeight();
      const footerSticky = $('.footer__sticky-buttons');      
      const footerStickyHeight = footerSticky.outerHeight();
      const menuInner = $('.header__menu__inner');

      menuInner.css('max-height', winH - headerH - footerStickyHeight);
    }
  });

  // hover for main menu
  $('.menu-item--sub, .header__main-nav__dropdown').on('mouseenter',function(){
    if(desktopQuery.matches) {
      // determine if menu should appear on other side (too close to edge)
      if ($(this).offset().left + 300 > $(window).outerWidth()) {
        $(this).find('.sub-menu, .dropdown__menu').css('left','auto').css('right','16px');
      } else {
        $(this).find('.sub-menu, .dropdown__menu').css('left','16px').css('right','auto');
      }
      // expand sub-menu and update aria
      // $(this).find('.sub-menu, .dropdown__menu').stop().slideDown();
      $(this).find('> a').prop('ariaExpanded', true);
    }
    
  }).on('mouseleave',function(){
    if(desktopQuery.matches) {
      // collapse sub-menu and update aria
      // $(this).find('.sub-menu, .dropdown__menu').stop().slideUp();
      $(this).find('> a').prop('ariaExpanded', false);
    }
    
  });

  // keyboard accessibility for menu
  $('.menu-item--sub > a').on('keydown',function(e){
    if(desktopQuery.matches) {
      // open sub menu on arrow down
      if (e.which == 40) {
        e.preventDefault();
        $(this).parent().trigger('mouseenter');
      }
    }
  });

  $('.menu-item--sub > a').on('keyup',function(e){
    if(desktopQuery.matches) {
      // close sub menu on esc
      if (e.which == 27) {
        e.preventDefault();
        $(this).siblings('.sub-menu').trigger('mouseleave');
      }
    }
  });

  // handle menu dropdowns when tabbing out
  $('.sub-menu__item:last-child a').on('focusout',function() {
    if(desktopQuery.matches) {
      $(this).closest('.menu-item--sub').trigger('mouseleave');
    }
    
  });

  // clicking somewhere random other than utility nav closes it
  $(document).on('click',function(e) {
    if(desktopQuery.matches) {
      let dropdownToggled = $('.header__utility-nav__dropdown.open, .header__main-nav__dropdown.open');
      if (!dropdownToggled.is(e.target) && dropdownToggled.has(e.target).length === 0) {
        dropdownToggled.removeClass('open');
      }
    }
    
  });

  // handle utility nav dropdowns when tabbing out
  $('.dropdown__menu__link:last-child').on('focusout',function() {
    if(desktopQuery.matches) {
      const dropdown = $(this).closest('.header__dropdown');

      if (dropdown.hasClass('open')) {
        dropdown.removeClass('open');
        $(this).closest('.header__dropdown').prop('ariaExpanded', false);
      }
    }
  });

  // utility nav dropdowns
  $('.header__main-nav__dropdown, .top-menu__dropdown').on('mouseenter click',function() {
    if(desktopQuery.matches) {
      const dropdown = $(this).closest('.header__dropdown');
      dropdown.addClass('open');
      dropdown.siblings().removeClass('open');
      $(this).prop('ariaExpanded', true);
    }
  }).on('mouseleave',function(){
    if(desktopQuery.matches) {
      const dropdown = $(this).closest('.header__dropdown');
      dropdown.removeClass('open');
      $(this).prop('ariaExpanded', false);
    }
    
  });

  // mobile menu toggle
  $('.header__menu-toggle:not(.toggle--simple)').on('click',function() {
    if(tabletQuery.matches) {
      // scroll down to menu if above
      if (!$('.header.fixed').length) {
        $('html, body').animate({ scrollTop: $('.header__inner').offset().top },300);
      }

      const menu = $('.header__menu');

      if (!$(this).hasClass('toggled')) {
        $(this).addClass('toggled').prop('ariaExpanded', true);
        menu.addClass('open');
        menu.find('.main-nav__list--mobile .menu-item').find('.menu-item__link').first().focus();
        disableScroll();
      } else {
        $(this).removeClass('toggled').prop('ariaExpanded', false);
        menu.removeClass('open');
        enableScroll();
      }

      const dropdown = $('.region-switcher__dropdown');
      const dropdownParent = $('.region-switcher');

      const branchDetails = $('.region-switcher__dropdown__content__branch-details');
      const switcherFields = branchDetails.siblings('.region-switcher__switcher');

      if (dropdown.hasClass('open')) {
        dropdown.removeClass('open');
        dropdownParent.removeClass('open');
        $(this).prop('ariaExpanded', false);
      }

      if (branchDetails.hasClass('hidden')) {
        branchDetails.show('slow').removeClass('hidden');
        switcherFields.hide('slow').addClass('hidden');
      }
    }
  });

  $('.header__menu-toggle.toggle--simple').on('click',function() {
    if (tabletQuery.matches) {
      const menu_panel = $(this).siblings('.header__content');

      if (!$(this).hasClass('toggled')) {
        $(this).addClass('toggled').prop('ariaExpanded', true);
        menu_panel.addClass('open').slideDown();
        menu_panel.find('a').first().focus();
        // disableScroll();
      } else {
        $(this).removeClass('toggled').prop('ariaExpanded', false);
        menu_panel.removeClass('open').slideUp();
        // enableScroll();
      }
    }
  });

  $('.menu-item--sub > a.menu-item__link').on('click',function(e) {    
    if(tabletQuery.matches) {
      e.preventDefault();
      if (!$(this).hasClass('open')) {
        $(this).addClass('open').prop('ariaExpanded', true);
        $(this).siblings('.sub-menu').addClass('open');
      } else {
        $(this).removeClass('open').prop('ariaExpanded', false);
        $(this).siblings('.sub-menu').removeClass('open');
      }
    }
  });

  $('.sub-menu__item > a.sub-menu__link--back').on('click',function(e) {    
    if(tabletQuery.matches) {
      e.preventDefault();
      if ($(this).closest('.sub-menu').hasClass('open')) {
        $(this).closest('.sub-menu').removeClass('open').prop('ariaExpanded', false);
        $(this).closest('.sub-menu').siblings('a.menu-item__link').removeClass('open').prop('ariaExpanded', false);
      }
    }
  });

  // focus off the last mobile list item returns focus to the close button
  $('.main-nav__list--mobile > li:last-child > a').on('focusout',function(){
    setTimeout(function(){
      if (!$('.main-nav__list--mobile :focus').length) {
        $('.header__menu-toggle').focus();
      }
    }, 100);
  });

  // pressing escape closes the mobile menu if open
  $(document).on('keydown',function(e){
    if (e.which == 27) {
      if ($('.header__menu-toggle.toggled').length) {
        $('.header__menu-toggle').trigger('click');
      }
      if ($('.header__utility-nav__dropdown.open, .header__main-nav__dropdown.open').length) {
        $('.header__utility-nav__dropdown.open, .header__main-nav__dropdown.open').find('.dropdown__top').focus().trigger('click');
      }
    }
  })

  $('.menu-item--sub > a').on('keydown',function(e){
    if(desktopQuery.matches) {
      // close sub menu on esc
      if (e.which == 27) {
        e.preventDefault();
        $(this).siblings('.sub-menu').trigger('mouseleave');
      }
    }
  });

  // keyboard accessibility for menu
  $('.header__main-nav__dropdown > a, .dropdown__top').on('keydown',function(e){
    if(desktopQuery.matches) {
      // open sub menu on arrow down
      if (e.which == 40) {
        e.preventDefault();
        $(this).parent().trigger('mouseenter');
      }
    }
  });
}
