export default () => {
  $('.video__poster__play').on('click',function(){
    $(this).closest('.video').addClass('clicked');
    const $vid = $(this).closest('.video').find('.video__code');
    if ($vid.length) {
      const vidCode = $vid.text();
      $vid.empty().html(vidCode);
      const vidSrc = $vid.find('iframe')[0].src;
      if (vidSrc.indexOf('?') == -1) {
        $vid.find('iframe')[0].src += '?autoplay=1';
        $vid.find('iframe').focus();
      } else {
        $vid.find('iframe')[0].src += '&autoplay=1';
        $vid.find('iframe').focus();
      }
    }
    $(this).closest('.video').find('.video__transcript').slideDown();
  });

  $('.video__transcript__toggle').on('click',function() {
    if (!$(this).hasClass('toggled')) {
      $(this).addClass('toggled').siblings('.video__transcript__content').slideDown();
      $(this).text('Hide Transcript');
    } else {
      $(this).removeClass('toggled').siblings('.video__transcript__content').slideUp();
      $(this).text('Show Transcript');
    }
  });
}